<template>
  <div class="apply">
    <div class="news-container">
      <div class="title">
        <h1 v-html="detailData[thisDetailId][thisNewsID].title">
          Can 5G bring about the rapid development of glasses-free 3D
          technology?
        </h1>
        <p>
          <span>
            Release time：<span
              v-html="detailData[thisDetailId][thisNewsID].time"
              >2020-04-27</span
            >
          </span>
        </p>
      </div>
      <article class="info" v-show="thisNewsID == 0 && thisDetailId == 0">
        <p class="MsoNormal" style="line-height: 150%">
          In the past, we read books, watch TV, and watch movies on a
          two-dimensional level. To experience the 3D effect, only wear special
          3D glasses, but not with glasses-free 3D. We can experience
          three-dimensional without using glasses visual feast. 
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          Imagine if this technology is integrated into our mobile phones,
          playing games is like being immersive, watching movies is more like
          traversing into the drama, from 2D to 3D, it is a qualitative leap.So
          will glasses-free 3D take this opportunity to take off in the 5G
          era? 
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          You can experience the 3D effect without using special glasses.
          Display three-dimensional graphics in the plane, according to the
          characteristics of human vision, form a three-dimensional sense.
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          <img src="/uploadfiles/2020/04/20200427153857064.png" alt="" />
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          <b>Follow the trend.</b> 
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          The improvement of 4G bandwidth has made mobile Internet compete with
          PC Internet and become the mainstream of the times. Then, due to the
          further improvement of bandwidth and delay, 5G provides unlimited
          possibilities for the transmission of large traffic and large amounts
          of data, especially in the visual application Will be developed
          rapidly. 5G paved the way for us to enter the era of 3D vision.
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          <b>Demand is the best driver.</b><b> </b>
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          Our human pursuit of visual experience has never stopped, from the
          original black and white lines to color images, from analog to digital
          images, from low-resolution to high-resolution, from two-dimensional
          plane to three-dimensional, now wearing special glasses experience 3 D
          can no longer meet our needs, we more and more yearning for naked eyes
          can experience 3 D effect, yearning for more scene experience 3 D,
          yearning to experience 3 D. anytime and anywhere. The so-called
          reading ten thousand books, walking ten thousand miles, knowledge only
          from the two-dimensional plane to the three-dimensional reality can be
          better absorbed, similarly, three-dimensional vision will also let us
          get more information faster and more intuitively. 
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          <img src="/uploadfiles/2020/04/20200427153834501.png" alt="" />
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          &nbsp;Visible naked eye 3 D technology will be a visual innovation,
          along with the rapid development of display technology ,5G
          transmission technology, content production technology, will also
          usher in new development opportunities. 
        </p>
      </article>
      <article class="info" v-show="thisNewsID == 1 && thisDetailId == 0">
        <p class="MsoNormal" style="line-height: 150%">
          In the current glasses-free 3D industry, glasses-free 3D display
          terminals are mainly based on external devices, and major enterprises
          still remain at the level of fighting for their own traditional
          hardware display terminals, which also leads to the dispersion of 3D
          data. Due to the non-interoperability of various platforms, the
          development of the high-quality 3D content industry is largely
          limited. A unified data platform has not been established, and benign
          interactive self-built content sharing cannot be achieved. The
          richness and scale of the content are lacking. There is still a long
          way to go in the popularity of the consumer market.
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          <b>Building a </b><b>glasses-free 3D</b
          ><b>&nbsp;ecosystem cannot be successful with a single company.</b>
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          Building a glasses-free 3D ecosystem cannot succeed even with a single
          company, even an industry giant. The glasses-free 3D industry is
          currently in its infancy, from technology patents, component chip
          manufacturing, terminal research and production supply and marketing,
          storage cloud, various data platforms and service platforms, and
          content production are all in the embryonic stage, due to the
          limitations and exclusivity of the company's system The friction
          coefficient of traditional industries is very high, the focusing
          ability is very poor, and it cannot bear the historical mission of
          establishing a global glasses-free 3D ecology.
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          <b>3D content is scarce and scattered.</b>
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          At present, major technology companies are committed to creating
          independent 3D platforms, such as Apple ’s ARkit and Facebook ’s
          Camera Effect Platform. Even though they all have a complete incentive
          and reward system for the content, due to the non-interoperability of
          each platform, Content cannot be circulated freely, which limits the
          development of excellent 3D content. For example, developers must
          choose which platform they want to join, and the consequence of
          joining one of them is that they cannot publish their results on other
          platforms. Even if their work has lost market on one platform, it
          cannot be transferred to other markets or platforms. . This is a fatal
          threat to content producers, and in the long run, excellent 3D content
          is bound to be negatively affected. And excellent 3D content is the
          core of the stable development of ecology. A survey questionnaire
          issued by American Boqin Law Firm shows that 38% of the respondents
          believe that the lack of excellent 3D content is the main challenge
          facing the 3D industry. The questionnaire includes at least 650 IT
          entrepreneurs, technology company executives and investors. In
          addition, due to the decentralization of 3D content, there is
          currently no search engine that can search the entire 3D resource
          database, which is crucial for the development of 3D content.<o:p
          ></o:p>
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          From the scattered sand of the industry to the establishment of a
          complete ecology, it also needs to work together from multiple
          dimensions such as technology, market, capital, policy, organizational
          form, production relationship, production mode, etc., to achieve
          win-win industrial integration. There is still a long way to go in the
          future. 
        </p>
      </article>
      <article class="info" v-show="thisNewsID == 2 && thisDetailId == 0">
        <p class="MsoNormal" style="line-height: 150%">
          In recent years, global 3D technologies, including 3D printing, 3D
          cameras, 3D games, 3D displays, and 3D software, have developed
          rapidly, and the market scale has continued to expand. According to
          the data of "Global 3D Technology Market Development Status and Future
          Trends" released by Markets &amp; Markets, the global 3D technology
          market size was USD 127.84 billion in 2016, and it is expected to
          reach USD 245.8 billion by 2022, with a compound annual growth rate of
          16.17%. In the upstream of the entire 3D industry, glasses-free 3D
          &nbsp;terminal products, 3D scanning, 3D modeling and surveying, etc.,
          have good development prospects. 
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          3D terminal products have become more and more extensive in the
          development of recent years, and the market scale is also growing.
          According to a report issued by Citibank in 2016, in the global 3D
          industry, the market size of the 3D display terminal alone has reached
          7.5 billion US dollars, of which 3.9 billion belongs to the software
          and content market. By 2025, the 3D industry will shift its focus from
          hardware to software and reach a scale of US $ 569 billion. In
          addition, Citi also boldly predicted that 3D smart devices will
          completely replace current smart phones in the future, and 3D content
          will become the subject of visual content. Now industries that mainly
          focus on pictures and 2D videos will shift to 3D. 3D technology will
          be widely used in various industries. Taking today's hot game industry
          as an example, more than half of games will be converted from 2D to 3D
          in the future, and holographic games will become mainstream. Other
          entertainment industries such as movies will also be occupied by
          3D. 
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          According to data from research institutes, domestic glasses-free 3D
          &nbsp;terminals reached 13.36 million in 2018, almost the sum of 2016
          and 2017, and terminal growth rates will remain high in the next few
          years. Reached 35.42 million units. 
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          <img src="/uploadfiles/2020/04/20200427151713375.png" alt="" />
        </p>
      </article>
      <article class="info" v-show="thisNewsID == 3 && thisDetailId == 0">
        <p class="MsoNormal" style="line-height: 150%">
          Remember "Avatar" ten years ago? The fascinating Pandora landscape,
          various forms of luminous plants, the majestic waterfalls rushing
          down, and the colorful flying dragons, will you let you have an
          endless aftertaste from the theater? "Avatar 2", which will be
          released at the end of 2020, is about to be presented with
          glasses-free 3D technology, which has attracted much attention and
          expectations. What is glasses-free 3D? Can we achieve glasses-free 3D?
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          <br />
        </p>
        <p class="MsoNormal" style="white-space: normal; line-height: 18px">
          <b>What is </b><b>glasses-free</b><b> 3D technology?</b
          ><b> </b>
        </p>
        <p class="MsoNormal" style="white-space: normal; line-height: 18px">
          As we all know, our human brain is an extremely complex nervous
          system. In the visual system, due to the distance of about 60mm
          between our two eyes, when we look at objects, the imaging of objects
          on the retinas of the left and right eyes will exist. There is a
          certain level of level difference, then this phenomenon is what we
          usually say about parallax. It is also because of this parallax that
          our brain can judge the distance between objects.
        </p>
        <p>
          <br />
        </p>
        <p class="MsoNormal" style="text-align: left; line-height: 150%">
          <img src="/uploadfiles/2020/04/20200427150619594.png" alt="" />
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          At the same time, the brain will reflect the two parallax images
          reflected in both eyes through the fusion reflection of the optic
          nerve center and the visual psychological reaction to form a
          three-dimensional stereoscopic feeling. For example, the dynamic image
          below uses the parallax of the eyes to form a three-dimensional
          effect.
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          The&nbsp;glasses-free&nbsp;3D technology splits the image displayed on
          the display through a grating or lens on this basis, so that the human
          eye receives different images, thus achieving 3D display. The slit
          grating display blocks the displayed content by placing a slit with
          appropriate parameters in front of the display panel. After a certain
          distance, the light reaching the human eye can be separated, and both
          eyes receive two parallax images. In the picture below, the human eye
          can see blue and green separately, which can produce a
          three-dimensional effect. 
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          <img src="/uploadfiles/2020/04/20200427151012953.png" alt="" />
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          The lenticular lens type uses the same principle, but the way of
          implementation is changed from a slit to a lens. The lens refracts
          different display content to different places in space through the
          refraction of light, and the content displayed when it reaches the
          human&nbsp;eye is separately, the human eye receives two images with
          parallax, which produces a three-dimensional effect. 
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          <img src="/uploadfiles/2020/04/20200427151041813.png" alt="" />
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          Therefore, the&nbsp;glasses-free&nbsp;stereoscopic display is still
          based on the principle of stereoscopic vision, but by improving the
          stereoscopic display, with specific optical occlusion and optical path
          propagation control, the images containing parallax are transmitted to
          our left and right eyes, respectively, and then the stereoscopic
          images are viewed. 
        </p>
      </article>
      <article
        class="info"
        v-show="(thisNewsID == 0 || thisNewsID == 1) && thisDetailId == 1"
      >
        <p class="MsoNormal">
          <span style="font-family: Times New Roman"
            >Every Spring Festival Gala on New Year's Eve is an appetizing
            "audiovisual dinner" on the audience's dinner table. In recent
            years, the "tech taste" of this big meal has become stronger and
            stronger. This year's Spring Festival Gala stage was the first to
            create a three-layer three-dimensional dance beauty, while using
            flying screen technology to create a 360-degree surround landscape,
            so that the audience can have the ultimate experience of "naked eye
            3D" in front of the screen.</span
          > 
        </p>
        <p class="MsoNormal">
          <img src="/uploadfiles/2020/04/20200428110151548.png" alt="" />
        </p>
        <p class="MsoNormal">
          <span style="font-family: Times New Roman"
            >Undoubtedly, "5G + 8K / 4K / VR" is the most popular "black
            technology" in the Spring Festival Gala this year. From standard
            definition, high-definition to 4K, 8K ultra-high-definition video
            technology, the leap-forward development of technological innovation
            has brought a refreshing audio-visual experience to the audience. In
            the live broadcast of the Spring Festival Gala this year, the 5G
            network has fully covered the main venue and branch venues of the
            Spring Festival Gala 2020, and the Central Radio and Television
            Station used 5G + 8K technology for the first time to achieve
            multi-camera shooting. The 5G network makes the picture transmission
            clearer and more stable, which is also a necessary condition for
            shooting, transmitting and producing the 8K version of the Spring
            Festival Gala.</span
          > 
        </p>
        <p class="MsoNormal">
          <span style="font-family: Times New Roman"
            >At the same time, the Spring Festival Gala also adopted the 4K
            accompanying high-definition production mode pioneered by the
            Central Radio and Television Station, and used a large number of
            more than 40 sets of 4K special shooting equipment such as orbit
            robots, drones, and online virtual systems for program production to
            achieve full-factor 4K HD TV smart live broadcast.</span
          > 
        </p>
        <p class="MsoNormal">
          <span style="font-family: Times New Roman"
            >In addition, through the Virtual Network Interactive Production
            Mode (VNIS) pioneered by the headquarter, China </span
          ><span style='font-family:"'>’</span
          ><span style='font-family:"'
            >s first national-level 5G new media platform-the Central
            Broadcasting and Television Station "Central Video" 5G new media
            platform achieved the first Spring Festival Gala VR (Virtual
            Reality) live broadcast this year , Presenting a three-dimensional
            panoramic view of watching the Spring Festival Gala in the era of
            all-media, making the technology "new year's dinner" more
            interesting.</span
          > 
        </p>
        <p class="MsoNormal">
          <span style='font-family:"'
            ><img
              src="/uploadfiles/2020/04/20200428110235485.png"
              alt=""
            /><br />
          </span>
        </p>
        <p class="MsoNormal">
          <span style="font-family: Times New Roman"
            >How to complete VR live broadcast in the huge Spring Festival
            Evening scene? The reporter saw in the video of </span
          ><span style='font-family:"'>“</span
          ><span style='font-family:"'
            >Decryption of VR Aerial Shooting in Zhengzhou Branch</span
          ><span style='font-family:"'>” </span
          ><span style='font-family:"'
            >that because the stage scene was too large, members of the shooting
            team used heavy-duty VR equipment. This VR device has a high
            resolution and can basically see everyone's facial
            expressions.</span
          > 
        </p>
        <p class="MsoNormal">
          <span style="font-family: Times New Roman"
            >This year's Spring Festival Gala stage, AR (augmented reality)
            technology, drones and other "black technologies" are still used,
            but the visual effects of the scenes and performances are more
            outstanding.</span
          > 
        </p>
        <p class="MsoNormal">
          <span style="font-family: Times New Roman"
            >In fact, many viewers are no strangers to these two technologies.
            As early as 2012, CCTV's Spring Festival Gala adopted AR technology
            for the first time, and it was used in actress Sa Dingding's
            "Everything" and other programs, which made people feel as if they
            were on the scene.</span
          > 
        </p>
        <p class="MsoNormal">
          <span style="font-family: Times New Roman"
            >"New tricks to keep the taste of the year" "Innovative technology
            shows the charm, vitality and innovation of the Spring Festival Gala
            in the new era" ... On social media, many netizens praised the
            technology innovation of the Spring Festival Gala. Statistics show
            that this year's Spring Festival Evening Network positive evaluation
            rate reached 98.65%, an increase of 1.67 percentage points compared
            to last year.</span
          > 
        </p>
      </article>
      <article class="info" v-show="thisNewsID == 2 && thisDetailId == 1">
        <p class="MsoNormal" style="line-height: 150%">
          <span style="font-family: Times New Roman"
            >Some people will find that the glasses-free 3D technology has
            clearly appeared, but the actual application is very few. Usually,
            the &nbsp;glasses-free 3D technology is not seen at all. Even in
            some large exhibition halls, such as movie theaters, the 3D
            projection technology is not widely used. . Why not use glasses-free
            3D projection in movie theaters?</span
          > 
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          <span style="font-family: Times New Roman"
            >If you want to watch 3d movies in a movie theater, you usually need
            to wear a pair of 3d glasses. A research group once conducted by a
            polytechnic wanted to remove the glasses. In cooperation with a
            scientific research institute, a movie theater was shown, and the
            audience could watch 3D movies without wearing 3D glasses.</span
          > 
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          <span style="font-family: Times New Roman"
            >Although glasses-free 3D technology has been around for a long
            time, traditional technology cannot achieve naked-eye viewing in
            movie theaters. The traditional parallax barrier technology uses an
            LCD screen to separate the left and right eyes to create a
            &nbsp;glasses-free 3D effect for the audience. However, this
            technology requires a certain distance and angle between the
            audience and the screen, which is not suitable for audiences sitting
            in different corners of the theater.</span
          > 
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          <span style="font-family: Times New Roman"
            ><img
              src="/uploadfiles/2020/04/20200428105731079.png"
              alt=""
            /><br />
          </span>
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          <span style="font-family: Times New Roman"
            >&nbsp;In the &nbsp;glasses-free 3D movies displayed by the
            researchers, they used 50 groups of lenses and mirrors to create a
            special optical system that achieves a multi-attention difference
            barrier effect on the screen, allowing viewers sitting in different
            corners of the theater to Watch 3D movies naked.</span
          > 
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          <span style="font-family: Times New Roman"
            >However, the position of the audience in the cinema is fixed on the
            seat, so their movement range is relatively limited, which makes the
            &nbsp;glasses-free 3D effect easier to achieve in the cinema. In
            order to allow all people in the cinema to see 3D images, they also
            adopted a new projector, but now they need to solve the problem of
            picture clarity.</span
          > 
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          <span style="font-family: Times New Roman"
            >Why not use glasses-free3D projection in movie theaters? The above
            is the result of an experiment. Although it is feasible and is the
            future development direction, the current technology of using
            &nbsp;glasses-free 3D holographic projection in movie theaters is
            not achievable, and &nbsp;glasses-free 3D technology is not
            recommended. For continuous viewing of more than hours, the movies
            are generally around 90 minutes.</span
          > 
        </p>
      </article>
      <article class="info" v-show="thisNewsID == 3 && thisDetailId == 1">
        <p class="MsoNormal" style="line-height: 150%">
          <span style="font-family: Times New Roman"
            >Harbin Map Publishing House publishes "The Three-Dimensional Vision
            3D China-The People's Republic of China 3D Atlas", which uses 3D
            visual effects in the map. The atlas is the first naked-eye 3D
            Chinese atlas in China, which is a collection of high-tech
            innovations in the current field of cartography. A whole new
            way.</span
          > 
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          <span style="font-family: Times New Roman"
            >It uses global land and sea terrain data, uses computer processing
            and grating 3D display technology, and uses high-precision grating
            materials with glass transparency, brightness and texture as the
            carrier, and then prints it through the most advanced domestic
            printing process.</span
          > 
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          <span style="font-family: Times New Roman"
            >When you open the map, the first thing you will see is the
            full-scale view of the earth in 3D. After the catalog, there are
            maps of China first, followed by maps of 34 provinces, all with
            naked-eye 3D effects.</span
          > 
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          <span style="font-family: Times New Roman"
            ><img
              src="/uploadfiles/2020/04/20200428104051923.png"
              alt=""
            /><br />
          </span>
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          <span style="font-family: Times New Roman"
            >Expand the atlas, the left page is a traditional map display: it
            can accurately display rich information such as traffic road
            network, administrative divisions and location of residential areas,
            as well as landmark landscape pictures.</span
          > 
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          <span style="font-family: Times New Roman"
            >The right page shows the naked-eye 3D raster map of the
            corresponding area: the landform landscape of each administrative
            area is presented like a real scene, and the plateau, mountains,
            plains, hills and basins are fully viewed, so that the innovative
            three-dimensional effect and the ordinary two-dimensional plane
            complement and complement each other.</span
          > 
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          <span style="font-family: Times New Roman"
            >he biggest feature of this map is that it supports naked-eye 3D
            viewing. Compared with traditional maps, 3D maps not only have a
            large amount of information and no size limitation, but also have
            high information reliability and faster update speed. The advantages
            are obvious. It can be seen that the naked-eye 3D raster technology
            makes the map "live" and subverts the traditional understanding of
            the map.</span
          > 
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          <span style="font-family: Times New Roman"
            >In addition to the flat map information, this brand-new map format
            also provides more intuitive and visible topographic information,
            allowing us to understand the landform of the motherland more
            intuitively. Various landforms and landscapes are presented like
            real scenes. Plateaus, mountains and rivers, plains, hills, and
            basins have a panoramic view, just like flying down to look at the
            earth.</span
          > 
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          <span style="font-family: Times New Roman"
            >&nbsp;<img src="/uploadfiles/2020/04/20200428104119188.png" alt=""
          /></span>
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          <span style="font-family: Times New Roman"
            >Others will ask: "In addition to the tall 3D maps, what are the
            practical uses?"</span
          > 
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          <span style="font-family: Times New Roman"
            >But it's not just a face value. Since our space is
            three-dimensional, the map after "dimensionality reduction" must
            have its limitations, but the three-dimensional map can perfectly
            restore our three-dimensional world.</span
          > 
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          <span style="font-family: Times New Roman"
            >For example, if you want to set up a wire, two poles are 15 meters
            high, the other is 20 meters high, and the poles are 50 meters
            apart. How long do we need at least? If you use a two-dimensional
            map to calculate, you can't calculate the difference in height
            between two poles. Of course, you can't get a specific value, but a
            3D map can easily solve this problem.</span
          > 
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          <span style="font-family: Times New Roman"
            >This example, similar to the math problem of elementary school
            students, certainly cannot fully illustrate the powerful functions
            of 3D maps. The reason why the 3D map can be called the black
            technology of the map world is because of the most accessible human
            technological breakthrough: autonomous driving cars.</span
          > 
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          <span style="font-family: Times New Roman"
            >When a self-driving car looks into the world, it will see a lot of
            things. It has a radar that measures the distance to the car next to
            it. It has a camera that captures color images on the street. Its
            lidar sensor emits laser pulses to measure the surrounding
            environment. For any car driven by a robot, one of the most
            important parts of the driving process is not what it sees, but how
            much it knows in advance about the situation of the road section it
            is passing.</span
          > 
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          <span style="font-family: Times New Roman"
            >3D maps need to send a lot of data, such data can usually come from
            sensors, radar and cameras. In addition, it also considers road
            conditions such as road construction operations, traffic lights,
            pedestrian crossings, buildings and other obstacles. Of course,
            weather conditions and driving visibility are also important
            factors.</span
          >
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          <span style="font-family: Times New Roman"
            >3D maps can now be used in virtual tours, post-disaster
            reconstruction and other fields.</span
          > 
        </p>
      </article>
      <article class="info" v-show="thisNewsID == 4 && thisDetailId == 1">
        <p class="MsoNormal" style="line-height: 150%">
          <span style="font-family: Times New Roman"
            >Recently, Bosch officially launched a new 3D display. This product
            uses a passive 3D technology display that can generate very
            realistic 3D effects compared to traditional displays, making it
            easier and faster for drivers to grab the screen Display
            information.</span
          > 
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          <span style="font-family: Times New Roman"
            >Bosch said that the 3D display screen is the latest development
            trend of the vehicle cockpit. On the movie screen, the 3D effect is
            mainly created to enhance the entertainment of the movie. But in
            vehicles, the meaning is completely different. "The stereoscopic
            effect of the display screen can help the driver to quickly grasp
            important visual information, including auxiliary system reminders
            and traffic jam reminders," said Dr. Steffen Berns. It will be more
            intuitive and more urgent. "In addition, a more realistic rear-view
            image during parking helps to detect obstacles in time and assists
            the driver to better grasp the remaining space between the rear
            fender and the parking lot wall. In densely populated city blocks,
            this 3D effect also plays a decisive role, because a map with a
            stereoscopic view allows the driver to clearly grasp which building
            should turn. At the same time, the passive 3D technology in Bosch's
            new display does not require drivers and passengers to wear 3D
            glasses or use visual tracking technology.</span
          > 
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          <span style="font-family: Times New Roman"
            ><img
              src="/uploadfiles/2020/04/20200428100424845.png"
              alt=""
            /><br />
          </span>
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          <span style="font-family: Times New Roman"
            >The eyes are responsible for 90% of human sensory perception. The
            car display that can only display information simply is outdated,
            and deepening the interaction between users and the display will
            become the future development direction. In order to meet the needs
            of the computing power of the 3D display screen, Bosch uses an
            on-board computer to coordinate the entire human-machine interface,
            and uses a single central control unit to integrate and implement
            all control functions. In addition, with online upgrade technology,
            vehicles such as infotainment systems can be updated in a timely
            manner like mobile phones.</span
          > 
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          <span style="font-family: Times New Roman"
            >In addition to meeting the functional requirements, Bosch is also
            conducting a comprehensive test of safety. Whether it is under
            temperature changes or vibration, the display must be able to
            maintain working conditions. Even if an unavoidable failure occurs,
            it is necessary to ensure that some necessary information required
            by the driver can be displayed. In addition, Bosch said that the
            operating system based on the 3D display can be applied to all
            vehicles.</span
          > 
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          <span style="font-family: Times New Roman"
            ><img
              src="/uploadfiles/2020/04/20200428100505470.png"
              alt=""
            /><br />
          </span>
        </p>
      </article>
      <article class="info" v-show="thisNewsID == 5 && thisDetailId == 1">
        <p class="MsoNormal" style="line-height: 150%">
          <span style="font-family: Times New Roman"
            >3D, we seem to be unfamiliar with this term. In real life, whether
            it</span
          ><span style='font-family:"'>’</span
          ><span style="font-family: 'Times New Roman'"
            >s movies, games, or videos, we are moving towards 3D. When we wear
            3D glasses in a movie theater, we watch one by one 3D coming towards
            us. When shooting, the kind of 3D stereoscopic picture brings us an
            immersive movie watching experience. But today's mainstream 3D
            stereoscopic display technology still can't let us get rid of the
            constraints of auxiliary equipment such as glasses, and glass free
            3Dcan let us have a gorgeous 3D visual experience without any
            auxiliary props.</span
          > 
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          <span style="font-family: 'Times New Roman'"
            ><img
              src="/uploadfiles/2020/04/20200428095950892.png"
              alt=""
            /><br />
          </span>
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          <span style="font-family: Times New Roman">The glass free 3D</span
          ><span style="font-family: Times New Roman">
            stereoscopic display technology is the most cutting-edge high-tech
            in the imaging industry. Its appearance has changed the visual
            fatigue of traditional flat images to people, and is also a
            technological revolution in the field of image production. Come a
            period of high-</span
          ><span style="font-family: Times New Roman"
            >speed gold development.</span
          > 
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          <span style="font-family: Times New Roman"
            ><img
              src="/uploadfiles/2020/04/20200428100027548.png"
              alt=""
            /><br />
          </span>
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          <span style="font-family: Times New Roman"
            >From a national perspective, in the country's latest "Thirteenth
            Five-Year Plan for the Development of New Display Technology",glass
            free 3D is characterized as "the next generation of display
            technology with the most vitality and will eventually become a
            common platform for display technology" Together with the laser
            display, it is regarded as two key technologies in common during the
            13th Five-Year Plan period. At the same time, the plan also pointed
            out that glass Free 3D, VR, and AR are not alternatives. They will
            jointly provide visual services for consumers with different
            preferences and different scenarios. With the support of national
            policies and technologies,glass free 3Dtechnology may usher in a new
            turning point.</span
          >
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          <span style="font-family: Times New Roman"
            >From the perspective of enterprises, Chinese enterprises have
            gradually moved from "Made in China" to a new stage of "Made in
            China", which has obvious advantages in the industrialization of
            glass free 3Dtechnology and content application development. Among
            domestic companies, the current screen transmittance can reach more
            than 92%, and the precision control is up to 0.05</span
          ><span style='font-family:"'>μ</span
          ><span style="font-family: 'Times New Roman'"
            >m, which effectively guarantees the high quality of the image, and
            has made great progress in multi-view conversion algorithms and
            human eye tracking algorithms. The product innovation brought by
            glass free 3Dtechnology can also create new markets and profit
            growth points for enterprises.</span
          > 
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          <span style="font-family: 'Times New Roman'"
            ><img
              src="/uploadfiles/2020/04/20200428100117142.png"
              alt=""
            /><br />
          </span>
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          <span style="font-family: Times New Roman"
            >From the consumer's point of view, the general public's awareness
            of glass free 3D products has been significantly improved compared
            to before. The use of glass free 3D technology in life scenes has
            improved people's perception of viewing things and made products
            towards Visualization and intelligent development, it enhances the
            human-computer interaction ability, making the machine not a cold
            tool, more often like a friend, the distance between the product and
            the consumer is shortened.</span
          > 
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          <span style="font-family: Times New Roman"
            ><img
              src="/uploadfiles/2020/04/20200428100145829.png"
              alt=""
            /><br />
          </span>
        </p>
        <p class="MsoNormal" style="line-height: 150%">
          <span style="font-family: Times New Roman"
            >Although glasses free 3D is a highly dynamic sunrise industry from
            all perspectives, in order for naked eye 3D technology to fully
            enter the consumer market, in addition to external support, "inside"
            also needs to be upgraded, empty hardware and not rich It is
            difficult for consumers to enjoy a complete 3D experience.
            Therefore, for enterprises, it is necessary to start on both
            hardware and content. On the one hand, through hardware development,
            many 3D devices can enter the market; on the other hand, content
            development, this content includes video, games, applications and so
            on.</span
          > 
        </p>
      </article>
    </div>
  </div>
</template>

<script>
export default {
  name: "news",
  data() {
    return {
      //新闻详情id
      thisNewsID: this.$route.query.newsId - 11,
      //新闻类型id 0/1 行业/公司
      thisDetailId: this.$route.query.id - 1,
      detailData: [
        [
          {
            newsID: "11",
            title:
              "Can 5G bring about the rapid development of glasses-free 3D technology?",
            img: require("../../assets/img/news/news_center_img3.jpg"),
            detail:
              "In the past, we read books, watch TV, and watch movies on a two-dimensional level. To experience the 3D effect, only wear special 3D glasses, but not with glasses-free 3D. We can experience three-dimensional without using glasses visual feast.",
            time: "2020-4-27",
          },
          {
            newsID: "12",
            title: "Analysis of the pain points of the naked eye 3D market.",
            img: require("../../assets/img/news/news_center_img3.jpg"),
            detail:
              "In the current glasses-free 3D industry, glasses-free 3D display terminals are mainly based on external devices, and major enterprises still remain at the level of fighting for their own traditional hardware display terminals, which also leads to the dispersion of 3D data. Due to the non-interoperability of various platforms, the development of the high-quality 3D content industry is largely limited.",
            time: "2020-4-27",
          },
          {
            newsID: "13",
            title: "3D technology market development status and future trends.",
            img: require("../../assets/img/news/news_center_img3.jpg"),
            detail:
              'In recent years, global 3D technologies, including 3D printing, 3D cameras, 3D games, 3D displays, and 3D software, have developed rapidly, and the market scale has continued to expand. According to the data of "Global 3D Technology Market Development Status and Future Trends" released by Markets & Markets, the global 3D technology market size was USD 127.84 billion in 2016, and it is expected to reach USD 245.8 billion by 2022, with a compound annual growth rate of 16.17%. In the upstream of the entire 3D industry, glasses-free 3D  terminal products, 3D scanning, 3D modeling and surveying, etc., have good development prospects.',
            time: "2020-4-27",
          },
          {
            newsID: "14",
            title:
              "The Glasses-Free 3D wind is blowing again, the mobile phone industry is ready to go?",
            img: require("../../assets/img/news/news_center_img3.jpg"),
            detail:
              "The Glasses-Free 3D technology is a new type of image display technology that allows viewers to obtain a highly realistic visual experience without wearing 3D glasses.",
            time: "2020-4-27",
          },
        ],
        [
          {
            title:
              "Glasses-free 3D, 5G + 8K, virtual reality, unmanned aerial vehicles ,these black technologies in the Spring Festival Evening of 2020.",
            img: require("../../assets/img/news/news_center_img3.jpg"),
            detail:
              "Every Spring Festival Gala on New Year's Eve is an appetizing 'audiovisual dinner' on the audience's dinner table. In recent years, the 'tech taste' of this big meal has become stronger and stronger. This year's Spring Festival Gala stage was the first to create a three-layer three-dimensional dance beauty, while using flying screen technology to create a 360-degree surround landscape, so that the audience can have the ultimate experience of 'naked eye 3D' in front of the screen.",
            time: "2020-4-28",
          },
          {
            title:
              "Glasses-free 3D, 5G + 8K, virtual reality, unmanned aerial vehicles ,these black technologies in the Spring Festival Evening of 2020.",
            img: require("../../assets/img/news/news_center_img3.jpg"),
            detail:
              "Every Spring Festival Gala on New Year's Eve is an appetizing 'audiovisual dinner' on the audience's dinner table. In recent years, the 'tech taste' of this big meal has become stronger and stronger. This year's Spring Festival Gala stage was the first to create a three-layer three-dimensional dance beauty, while using flying screen technology to create a 360-degree surround landscape, so that the audience can have the ultimate experience of 'naked eye 3D' in front of the screen.",
            time: "2020-4-28",
          },
          {
            title: "Why not use glasses-free 3D projection in movie theaters?",
            img: require("../../assets/img/news/news_center_img3.jpg"),
            detail:
              "Some people will find that the glasses-free 3D technology has clearly appeared, but the actual application is very few. Usually, the  glasses-free 3D technology is not seen at all. Even in some large exhibition halls, such as movie theaters, the 3D projection technology is not widely used. . Why not use glasses-free 3D projection in movie theaters?",
            time: "2020-8-16",
          },
          {
            newsID: "14",
            title:
              "China's first naked-eye 3D map-the original map can be seen like this.",
            img: require("../../assets/img/news/3Dearth.jpeg"),
            detail:
              "Harbin Map Publishing House publishes 'The Three-Dimensional Vision 3D China-The People's Republic of China 3D Atlas', which uses 3D visual effects in the map. The atlas is the first naked-eye 3D Chinese atlas in China, which is a collection of high-tech innovations in the current field of cartography. A whole new way.",
            time: "2020-8-16",
          },
          {
            newsID: "15",
            title: "Glasses-free3D display by car is here.",
            img: require("../../assets/img/news/car3D.jpeg"),
            detail:
              "Recently, Bosch officially launched a new 3D display. This product uses a passive 3D technology display that can generate very realistic 3D effects compared to traditional displays, making it easier and faster for drivers to grab the screen Display information.",
            time: "2020-4-28",
          },
        ],
      ],
    };
  },
  mounted() {
    console.log(this.$route.query.id - 1);
  },
  watch: {
    $route(to, from) {
      //   this.titleActive = to.query.id
      this.thisNewsID = to.query.newsId - 11;
      this.thisDetailId = to.query.id - 1;
      
    },

  },
};
</script>

<style lang="less" scoped>
.apply {
  padding-top: 3%;
  .news-container {
    text-align: center;
    margin: 0 auto;
    max-width: 1400px;
    .title {
      text-align: center;
      padding-bottom: 0px;
      background: none;
      color: #666;
      h1 {
        margin: 0;
        padding-bottom: 15px;
        font-size: 26px;
        font-weight: normal;
      }
      p {
        margin: 0;
        border-bottom: 1px dashed #d2d2d2;
        padding-bottom: 20px;
        span {
          margin: 0 36px;
          color: #999;
          font-size: 14px;
        }
      }
    }
    .info {
      p {
        font-size: 14px;
        color: #666;
        line-height: 26px;
        margin: 20px 0;
      }
    }
  }
}
</style>>
